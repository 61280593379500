<template>
  <div>
    <b-modal
      :title="
        typeTransaction == 'credit'
          ? $t('tabs.credit') + userSelect.userName
          : $t('tabs.debit') + userSelect.userName
      "
      v-model="modalShow"
      centered
      hide-footer
      size="sm"
    >
      <div v-if="!userLoggedNotWallet">
        <!-- <div class="row mb-2">
          <div
            class="
              col-md-12
              d-flex
            "
          >
            <h4>{{$t('labels.balanceuser')}}</h4>
            <h3 class="ml-1">
              {{ walletSelectUser.balance.balance | currency({ symbol: walletSelectUser.balance.currency }) }}
            </h3>
          </div>

          <div
            class="
              col-md-12
              d-flex
            "
            v-if="walletSelectUserLogged.balance && typeTransaction == 'credit'"
          >
            <h4>{{$t('labels.balanceyou')}}</h4>
            <h3 class="ml-1">
              {{ walletSelectUserLogged.balance.balance | currency({ symbol: walletSelectUserLogged.balance.currency }) }}
            </h3>
          </div>
        </div> -->

        <validation-observer ref="simpleRules">
          <b-form>
            <b-row class="d-flex justify-content-center">
              <!-- <b-col md="6">
                <b-form-group
                  label="Currencies"
                  v-if="walletSelectUser.currencies.length > 0"
                >
                  <template #label>{{$t('labels.currencies')}}</template>
                  <v-select
                    :value="walletSelectUser.balance.currency"
                    placeholder="Currencies"
                    label="currency"
                    :options="walletCurrencyOptions"
                    :reduce="(item) => item.currency"
                    @input="getBalanceMod({ currency: $event, walletIdUser: walletSelectUser._id, walletIdUserLogged: walletSelectUserLogged._id,  })"
                    :searchable="false"
                    :clearable="false"
                    name="currency"
                    rules="required"
                  />
                </b-form-group>
                <div v-else>
                    Wallet has no currencies!!!
                </div>
              </b-col> -->
              <b-col md="6" v-if="!validateWhitelabel()">
                <b-form-group label="Amount to recharge">
                  <template #label>{{
                    typeTransaction == "credit"
                      ? $t("labels.amounttorecharge")
                      : $t("labels.amounttodebit")
                  }}</template>
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required|min:0"
                    ref="AmountInput"
                  >
                    <money
                      v-model="transaction.amount"
                      v-bind="{ prefix: currencySelect, masked: false }"
                      class="form-control"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="validWalletAmounTranferir && typeTransaction === 'credit' " class="text-danger"
                      >{{ $t('user-list.messages.walletAmounTranferirError') }}</small
                    >
                    <small v-if="validWalletAmountDebit && typeTransaction === 'debit' " class="text-danger"
                      >{{ $t('user-list.messages.walletAmountDebitError') }}</small
                    >
                  </validation-provider>
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="description"
                >
                  <template #label>{{$t('labels.description')}}</template>
                  <validation-provider
                      #default="{ errors }"
                      name="Description"
                  >
                    <b-form-input
                        id="description"
                        v-model="transaction.description"
                        :state="errors.length > 0 ? false:null"
                        name="description"
                        :placeholder="$t('user-list.placeholder.transactionDescription')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                v-if="!validateWhitelabel()"
                class="d-flex justify-content-center"
              >
                <b-button
                  :variant="
                    typeTransaction == 'credit'
                      ? 'outline-success'
                      : 'outline-danger'
                  "
                  size="sm"
                  @click="sumAmount({ amount: 100 })"
                  class="mr-1"
                >
                  <feather-icon
                    :icon="
                      typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon'
                    "
                    size="16"
                  />
                  100
                </b-button>
                <b-button
                  :variant="
                    typeTransaction == 'credit'
                      ? 'outline-success'
                      : 'outline-danger'
                  "
                  size="sm"
                  @click="sumAmount({ amount: 1000 })"
                  class="mr-1"
                >
                  <feather-icon
                    :icon="
                      typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon'
                    "
                    size="16"
                  />
                  1.000
                </b-button>
                <b-button
                  :variant="
                    typeTransaction == 'credit'
                      ? 'outline-success'
                      : 'outline-danger'
                  "
                  size="sm"
                  @click="sumAmount({ amount: 10000 })"
                >
                  <feather-icon
                    :icon="
                      typeTransaction == 'credit' ? 'PlusIcon' : 'MinusIcon'
                    "
                    size="16"
                  />
                  10.000
                </b-button>
              </b-col>
              <b-col cols="12"
                ><b-alert
                  class="mr-1"
                  variant="danger"
                  :show="validateWhitelabel()"
                  >{{ $t('user-list.messages.validateDocuments') }}</b-alert
                ></b-col
              >

              <b-col cols="12" class="mt-2 d-flex justify-content-end">
                <b-button
                  variant="primary"
                  type="submit"
                  v-if="!validateWhitelabel()"
                  @click.prevent="confirmeTransaction"
                >
                  <feather-icon icon="CheckIcon" size="16" />
                  {{
                    typeTransaction == "credit"
                      ? $t("buttons.recharge")
                      : $t("buttons.retirement")
                  }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        <h5>
          {{ $t('user-list.messages.userLoggedNotWallet') }}
        </h5>
      </div>
    </b-modal>
    <user-money-withdrawal-by-pin ref="userMoneyWithdrawalByPin" @fetch-user-data="$emit('fetchUser')"/>
    <UserVoucher ref="UserVoucher" />
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BTabs,
  BTab,
  BAlert,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alphaNum, min, email } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import userStoreModule from "../userStoreModule";
import UserVoucher from "./UserVoucher.vue";
import store from "@/store";
import { getUserData, validateTokenRefreshData } from "@/auth/utils";
import axios from "@/libs/axios";
import moment from "moment";
import UserMoneyWithdrawalByPin from "@/views/apps/user/users-list/UserMoneyWithdrawalByPin.vue";
import { axiosErrorHandle } from '@core/utils/errorHandler'
import {translatableText} from "@core/utils/utils";

export default {
  components: {
    UserMoneyWithdrawalByPin,
    UserVoucher,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    integer,
    alphaNum,
    min,
    email,
    modalShow: false,
    typeTransaction: "credit",
    userSelect: {},
    walletSelectUserLogged: {},
    userLoggedNotWallet: false,
    transaction: {
      amount: 0,
      description: '',
      reference: null,
      details: {
        description: "",
        type: "",
      },
    },
    walletSelectUser: {
      balance: {
        balance: 0,
        currency: "",
      },
      currencies: [],
    },
  }),
  watch: {
    modalShow(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.AmountInput.$el.querySelector("input").focus();
        }, 200);
      } else {
        this.transaction = {
          amount: 0,
          reference: null,
          details: {
            description: "",
            type: "",
          },
        };
        this.$emit("fetchUser");
      }
    },
  },
  computed: {
    walletCurrencyOptions() {
      if (
        this.walletSelectUser.currencies.length > 0 &&
        this.typeTransaction == "credit"
      ) {
        // return los iguales a walletSelectUserLogged
        return this.walletSelectUser.currencies.filter((currencieUser) => {
          return this.walletSelectUserLogged.currencies.find(
            (currencieUserLogged) => {
              return currencieUser.currency === currencieUserLogged.currency;
            }
          );
        });
      }
      return [];
    },
    currencySelect() {
      return this.$store.state.whitelabelCurrencyNabvar.currency + " ";
    },
    validWalletAmounTranferir() {
      const user = JSON.parse(localStorage.getItem("userData"));
      const whitelabelSelectedCurrency = this.$store.state.whitelabelCurrencyNabvar.currency
      const userLoggedCurrencyObject = user.wallet.currencies.find(currency => currency.currency === whitelabelSelectedCurrency )
      return (
        this.transaction.amount > userLoggedCurrencyObject.balance
      );
    },
    validWalletAmountDebit(){
      const whitelabelSelectedCurrency = this.$store.state.whitelabelCurrencyNabvar.currency
      const userSelectedCurrencyObject = this.userSelect.wallet.currencies.find(currency => currency.currency === whitelabelSelectedCurrency )
      return (
          this.transaction.amount > userSelectedCurrencyObject.balance
      );
    }
  },
  methods: {
    showCreditDebit(user, typeTransaction) {
      // commit wallet select
      const { userName: userNameLogged } = JSON.parse(
        localStorage.getItem("userData")
      );
      const isWithdrawByPinConfig = this.$store.state.whitelabelCurrencyNabvar.whitelabel.withdrawalByPin;

      // if(typeTransaction == 'credit'){
      //   this.getWalletUserLogged(userNameLogged)
      // }else{
      //   this.userLoggedNotWallet = false;
      // }
      // this.getWalletUserLogged(userNameLogged);
      // this.getWalletUser(user.userName);
      this.userSelect = user;
      this.typeTransaction = typeTransaction;

      if (isWithdrawByPinConfig && typeTransaction === 'debit') {
        this.withdrawalByPinStatus = "";
        this.$refs.userMoneyWithdrawalByPin.setUserData(user);
        this.$refs.userMoneyWithdrawalByPin.setWithdrawalByPinModal(true);
      } else {
        this.modalShow = true;
      }
    },

    //Jeanrosa
    showUserVoucher(payload) {
      this.$refs.UserVoucher.showModal(payload);
    },
    async confirmeTransaction() {
      // validar formulario
      if (this.validWalletAmounTranferir && this.typeTransaction === 'credit') return;
      if (this.validWalletAmountDebit && this.typeTransaction === 'debit') return;
      const validate = await this.$refs.simpleRules.validate();
      if (!validate) return;

      //  pregustar si esta seguro de realizar la transaccion
      const confirme = await this.$swal({
        title: this.$t('are_sure_perform_operation'),
        text: this.$t('wont_able_to_revert_this'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("buttons.yes"),
        cancelButtonText: this.$t("buttons.no"),
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger ml-1",
        },
      });
      // si esta seguro se ejecuta la transaccion
      if (confirme.value) this.setCreditOrDebit();

      // si no esta seguro se cierra el confirm
    },

    async setCreditOrDebit() {
      try {

        const user = JSON.parse(
            localStorage.getItem("userData")
        );

        const payload = {
          mainUserId: user._id,
          secondaryUserId: this.userSelect._id,
          amount: this.transaction.amount,
          currencyAbv:
          this.$store.state.whitelabelCurrencyNabvar.currency,
          transactionType:
              this.typeTransaction === "credit" ? "CREDIT" : "DEBIT",
          description : this.transaction.description ? this.transaction.description : 'Backoffice operation',
          executorId : user._id
        };

        // procesar la operacion
        const operation = await this.$store.dispatch("app-user/setCreditOrDebit", payload)

        // preguntar si desea realizar otros operacion
        //this.otherOperation();
        //Jean Rosa ---------------
        this.showUserVoucher({
            date : moment(operation.date).format('DD/MM/YYYY hh:mm:ss A'),
            ticketNumber : operation._id,
            userNameDestination : this.typeTransaction === "credit" ? this.userSelect.userName : user.userName,
            userNameOrigin : this.typeTransaction === "credit" ? user.userName : this.userSelect.userName,
            amount: this.transaction.amount,
            typetTansaction  : this.typeTransaction === "credit" ? "CREDIT" : "DEBIT"
        });
        this.modalShow = false;
        this.resetForm();
        validateTokenRefreshData();
        //Jean Rosa fin ------------
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: await translatableText({text: axiosErrorHandle(error).toString()}),
            variant: "danger",
          },
        });
      }
    },
    async otherOperation() {
      // mostrar mensaje de exito y preguntar si desea realizar otra operacion
      const otherOperation = await this.$swal({
        title: this.$t('user-list.messages.successfulOperation'),
        text: this.$t('user-list.messages.anotherOperation'),
        icon: "success",
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no'),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      });

      // limpiar el formulario
      this.resetForm();

      if (otherOperation.value) {
        // en caso de que desee realizar otra operacion se consulta el balance y focus en el input amount
        setTimeout(() => {
          this.$refs.AmountInput.$el.querySelector("input").focus();
        }, 600);

        const payload = {
          walletIdUser: this.walletSelectUser._id,
          walletIdUserLogged: this.walletSelectUserLogged._id,
          currency: this.walletSelectUser.balance.currency,
        };
        this.getBalanceMod(payload);
      } else {
        // en caso de que no desee realizar otra operacion se cierra el modal y refresca la lista de users
        this.modalShow = false;
      }
    },
    resetForm() {
      this.transaction = {
        amount: 0,
        reference: null,
        details: {
          description: "",
          type: "",
        },
      };
      //  reset validate
      this.$refs.simpleRules.reset();
    },
    async getBalanceMod({ currency, walletIdUser, walletIdUserLogged }) {
      try {
        const resUserLogged = await this.getBalanceWallet({
          currency,
          walletId: walletIdUserLogged,
        });
        const resUser = await this.getBalanceWallet({
          currency,
          walletId: walletIdUser,
        });

        this.walletSelectUserLogged.balance.balance =
          resUserLogged.data.balance;
        this.walletSelectUserLogged.balance.currency = currency;

        this.walletSelectUser.balance.balance = resUser.data.balance;
        this.walletSelectUser.balance.currency = currency;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: await translatableText({text: axiosErrorHandle(error).toString()}),
            variant: "danger",
          },
        });
      }
    },
    async getBalanceWallet({ currency, walletId }) {
      try {
        const res = await this.$store.dispatch("app-user/fetchUserWallet", {
          currency,
          walletId,
        });
        return res;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: "XIcon",
            text: await translatableText({text: axiosErrorHandle(error).toString()}),
            variant: "danger",
          },
        });
      }
    },

    async getWalletUser(username) {
      try {
        // paramas router username
        const res = await this.$store.dispatch("app-user/walletUser", {
          username: username,
        });
        this.walletSelectUser = res.data[0];
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: "XIcon",
            text: await translatableText({text: axiosErrorHandle(error).toString()}),
            variant: "danger",
          },
        });
      }
    },

    async getWalletUserLogged(username) {
      try {
        // paramas router username
        const res = await this.$store.dispatch("app-user/walletUser", {
          username: username,
        });
        console.log(res.data[0]), "res.data[0]";
        this.walletSelectUserLogged = res.data[0];
        console.log(
          "🚀 ~ file: UsersWalletCreditDebit.vue ~ line 444 ~ getWalletUserLogged ~ this.walletSelectUserLogged",
          this.walletSelectUserLogged
        );
      } catch (error) {
        this.userLoggedNotWallet = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            icon: "XIcon",
            text: await translatableText({text: axiosErrorHandle(error).toString()}),
            variant: "danger",
          },
        });
      }
    },
    sumAmount({ amount }) {
      this.transaction.amount += amount;
    },
    validateWhitelabel() {
          // Valida que si el documento de identidad es requerido por el whitelabel, si el documento es valido y sea un jugador
          if (
              this.userSelect.whitelabels &&
              this.userSelect.whitelabels.some((w) => w.required_id) &&
              !this.userSelect.documents.fileIdValidate &&
              this.userSelect.typeUser === "Player"
          ) {
              return true;
          }
          return false;
      },
  },

  created() {
    // register module store validar si ya esta registrado
    if (!store.hasModule("app-user")) {
      store.registerModule("app-user", userStoreModule);
    }
  },
  destroyed() {
    // unregister module store
    if (store.hasModule("app-user")) {
      store.unregisterModule("app-user");
    }
  },
};
</script>
